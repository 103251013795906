import axios from 'axios';

const AxiosInstance = axios.create();

// AxiosInstance.interceptors.response.use(
//   (response) => {
//     if (typeof response.data === 'object' || Array.isArray(response.data)) {
//       return mapKeysCamelCase(response.data)
//     }

//     return response.data
//   },
//   (error) => {
//     // expired login token
//     console.log(error)
//     if (error.response.status === 401) {
//       console.log('401 Error')
//       // need redirect to login
//       // doLogout()
//     }
//     // eslint-disable-next-line prefer-promise-reject-errors
//     return Promise.reject(
//       {
//         data: (error.response && error.response.data) || 'Axios Something went wrong',
//         errorCode: error.response.status
//       }
//     )
//   }
// )

export default AxiosInstance;
