/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import Api from 'src/utils/Api';
import axios from 'src/utils/AxiosInstance';
import Commons from 'src/utils/Commons';

const initialState = {
  events: [],
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null,
  error: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(state, action) {
      const { events } = action.payload;

      state.events = events;
    },
    createEvent(state, action) {
      const { event } = action.payload;

      state.events = [...state.events, event];
    },
    selectEvent(state, action) {
      const { eventId = null } = action.payload;
      state.isModalOpen = true;
      state.selectedEventId = eventId;
    },
    updateEvent(state, action) {
      const { event } = action.payload;

      state.events = _.map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(state, action) {
      const { eventId } = action.payload;

      state.events = _.reject(state.events, { id: eventId });
    },
    selectRange(state, action) {
      const { start, end, allDay } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end,
        allDay
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
    setErrors(state, action) {
      const { error } = action.payload;
      state.error = error;
    }
  }
});

export const { reducer } = slice;

const eventColors = [
  '#606dbb', '#5850EC',
  '#257e4a', '#528617', '#D54899',
  '#265613', '#864679', '#645309',
  '#892959', '#207664', '#572457',
  '#620869', '#602049', '#842325',
  '#974159', '#661403', '#434165',
  '#267596', '#590795', '#433090',
  '#174989', '#165458', '#957281',
  '#886800', '#481115'
];
const usedColors = {};
const getDarkColor = () => {
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 10);
  }
  return color;
};

const remapEventData = (event) => {
  const getColor = (place) => {
    const color = usedColors[`ev_cus_${place}`]
                    || eventColors.find((c) => !Object.values(usedColors).includes(c))
                    || getDarkColor();
    usedColors[`ev_cus_${place}`] = color;
    return color;
  };
  getColor(event.place);
  return {
    ...event,
    start: `${event.date} ${event.start_time}`,
    end: `${event.date} ${event.end_time}`,
    allDay: event.all_day === 1 || event.all_day === true,
    color: usedColors[`ev_cus_${event.place}`]
  };
};

export const getEvents = () => async (dispatch) => {
  try {
    const response = await axios.get(Api.getApiUrl('v1/calendar-events'));
    const data = {
      events: response.data
    };
    const events = data.events.map((event) => remapEventData(event));
    // remap events data to Fullcalendar format
    dispatch(slice.actions.getEvents({ events }));
  } catch (err) {
    console.log(err);
    // remap events data to Fullcalendar format
    dispatch(slice.actions.getEvents({ events: [] }));
    dispatch(slice.actions.setErrors({ error: 'Failed to get events data' }));
  }
};

export const createEvent = (event) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(Api.getApiUrl('v1/calendar-events'), event)
      .then((resp) => {
        dispatch(slice.actions.createEvent({ event: remapEventData(resp.data) }));
        resolve(remapEventData(resp.data));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const selectEvent = (eventId) => async (dispatch) => {
  dispatch(slice.actions.selectEvent({ eventId }));
};

export const updateEventDrop = (event) => async (dispatch) => {
  // return;
  axios
    .put(
      Api.getApiUrl(`v1/calendar-events/${event.id}`),
      event
    )
    .then((resp) => {
      dispatch(slice.actions.updateEvent({ event: remapEventData(resp.data) }));
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const updateEvent = (event) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        Api.getApiUrl(`v1/calendar-events/${event.id}`),
        event
      )
      .then((resp) => {
        dispatch(slice.actions.updateEvent({ event: remapEventData(resp.data) }));
        resolve(remapEventData(resp.data));
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const deleteEvent = (eventId) => async (dispatch) => {
  await axios.delete(Api.getApiUrl(`v1/calendar-events/${eventId}`));

  dispatch(slice.actions.deleteEvent({ eventId }));
};

export const selectRange = (start, end, allDay) => (dispatch) => {
  dispatch(slice.actions.selectRange({
    start,
    end,
    allDay
  }));
};

export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
