import React from 'react';
import PropTypes from 'prop-types';

const DefaultGuard = ({ children }) => {
  return <>{children}</>;
};

DefaultGuard.propTypes = {
  children: PropTypes.node
};

export default DefaultGuard;
