import React, { useCallback, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
// import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
// import SettingsNotification from 'src/components/SettingsNotification';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import './i18n';
import { getI18n } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/vi';
import {
  Font
} from '@react-pdf/renderer';
import normalFont from './assets/fonts/GenShinGothic-Medium.ttf';
import lightFont from './assets/fonts/GenShinGothic.ttf';
import boldFont from './assets/fonts/GenShinGothic-Bold.ttf';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  const registerFonts = useCallback(() => {
    Font.register({
      family: 'customFont',
      fonts: [
        {
          src: lightFont,
          fontStyle: 'normal',
          fontWeight: 300
        },
        {
          src: normalFont,
          fontStyle: 'normal',
          fontWeight: 'normal'
        },
        {
          src: boldFont,
          fontStyle: 'normal',
          fontWeight: 'bold'
        },
        {
          src: boldFont,
          fontStyle: 'normal',
          fontWeight: '400'
        },
      ],
    });
    Font.registerHyphenationCallback((word) => {
      if (word.length === 1) {
        return [word];
      }

      return Array.from(word)
        .map((char) => [char, ''])
        .reduce((arr, current) => {
          arr.push(...current);
          return arr;
        }, []);
    });
  }, []);

  useEffect(() => {
    registerFonts();
  }, [registerFonts]);

  moment().locale(getI18n().language);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <Router history={history}>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                <GoogleAnalytics />
                {/* <CookiesNotification /> */}
                {/* <SettingsNotification /> */}
                {renderRoutes(routes)}
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
