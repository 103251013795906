import _ from 'lodash';

const mapKeysDeep = (data, callback) => {
  if (_.isArray(data)) {
    return data.map((innerData) => mapKeysDeep(innerData, callback));
  } if (_.isPlainObject(data)) {
    return _.mapValues(_.mapKeys(data, callback), (val) => mapKeysDeep(val, callback));
  }
  return data;
};

/**
 * Convert Snake Case Array or Object to Camel Case
 */
export const mapKeysCamelCase = (data) => mapKeysDeep(data, (_value, key) => _.camelCase(key));

/**
 * Convert Camel Array or Object to Snake Case
 */
export const mapKeysSnakeCase = (data) => mapKeysDeep(data, (_value, key) => {
  // return /^[a-z0-9]+$/.test(key) ? key : _.snakeCase(key)
  return _.snakeCase(key);
});
