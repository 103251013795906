import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
// import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
// import ManagerGuard from 'src/components/ManagerGuard';
// import AdminGuard from 'src/components/AdminGuard';
import DefaultGuard from 'src/components/DefaultGuard';
import AdminStaffGuard from 'src/components/AdminStaffGuard';
import GuestGuard from 'src/components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || DefaultGuard;
        const role = route.role || [];
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard role={role}>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        exact: true,
        path: '/app/management/users',
        guard: AdminStaffGuard,
        role: ['admin'],
        component: lazy(() => import('src/views/users/UserListView'))
      },
      {
        exact: true,
        path: '/app/management/users/create',
        guard: AdminStaffGuard,
        role: ['admin'],
        component: lazy(() => import('src/views/users/UserCreateView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin'],
        path: '/app/management/users/:id/edit',
        component: lazy(() => import('src/views/users/UserEditView'))
      },
      {
        exact: true,
        path: '/app/management/customers',
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        component: lazy(() => import('src/views/customers/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/create',
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        component: lazy(() => import('src/views/customers/CustomerCreateView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        path: '/app/management/customers/:id/edit',
        component: lazy(() => import('src/views/customers/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/loans',
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        component: lazy(() => import('src/views/loans/LoanListView'))
      },
      {
        exact: true,
        path: '/app/management/loans/create',
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        component: lazy(() => import('src/views/loans/LoanCreateView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        path: '/app/management/loans/:id/edit',
        component: lazy(() => import('src/views/loans/LoanEditView'))
      },
      {
        exact: true,
        path: '/app/management/loan-entrustments',
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        component: lazy(() => import('src/views/loanEntrustments/LoanEntrustmentListView'))
      },
      {
        exact: true,
        path: '/app/management/loan-entrustments/create',
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        component: lazy(() => import('src/views/loanEntrustments/LoanEntrustmentCreateView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        path: '/app/management/loan-entrustments/:id/edit',
        component: lazy(() => import('src/views/loanEntrustments/LoanEntrustmentEditView'))
      },
      {
        exact: true,
        path: '/app/management/savings',
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        component: lazy(() => import('src/views/savings/SavingListView'))
      },
      {
        exact: true,
        path: '/app/management/savings/create',
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        component: lazy(() => import('src/views/savings/SavingCreateView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        path: '/app/management/savings/:id/edit',
        component: lazy(() => import('src/views/savings/SavingEditView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        path: '/app/management/settings/terms-of-use',
        component: lazy(() => import('src/views/settings/TermsOfUseEditView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        path: '/app/management/settings/terms-of-loan',
        component: lazy(() => import('src/views/settings/TermsOfLoanEditView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        path: '/app/management/settings/faq',
        component: lazy(() => import('src/views/settings/FaqEditView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        path: '/app/management/settings/contact',
        component: lazy(() => import('src/views/settings/ContactEditView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        path: '/app/management/settings/loan-for-life',
        component: lazy(() => import('src/views/settings/LoanForLifeEditView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        path: '/app/management/settings/loan-for-business',
        component: lazy(() => import('src/views/settings/LoanForBusinessEditView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        path: '/app/management/settings/loan-entrustment',
        component: lazy(() => import('src/views/settings/LoanEntrustmentEditView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        path: '/app/management/saving-interest-rates',
        component: lazy(() => import('src/views/interestRates/SavingInterestRatesListView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        path: '/app/management/loan-interest-rates',
        component: lazy(() => import('src/views/interestRates/LoanInterestRatesListView'))
      },
      {
        exact: true,
        guard: AdminStaffGuard,
        role: ['admin', 'staff'],
        path: '/app/management/settings/transfer-money',
        component: lazy(() => import('src/views/settings/TransferMoneyEditView'))
      },
      // {
      //   exact: true,
      //   path: '/app/reports/dashboard-alternative',
      //   component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/reports',
      //   component: () => <Redirect to="/app/reports/dashboard" />
      // },
      // {
      //   exact: true,
      //   path: '/app/social/feed',
      //   component: lazy(() => import('src/views/social/FeedView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/social/profile',
      //   component: lazy(() => import('src/views/social/ProfileView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/social',
      //   component: () => <Redirect to="/app/social/profile" />
      // },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
