/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  // Avatar,
  Box,
  // Chip,
  Divider,
  Drawer,
  Hidden,
  // Link,
  List,
  ListSubheader,
  // Typography,
  makeStyles
} from '@material-ui/core';
import {
  Briefcase as BriefcaseIcon,
  // Calendar as CalendarIcon,
  // ShoppingCart as ShoppingCartIcon,
  // Folder as FolderIcon,
  // BarChart as BarChartIcon,
  // Lock as LockIcon,
  // UserPlus as UserPlusIcon,
  // AlertCircle as AlertCircleIcon,
  // Trello as TrelloIcon,
  User as UserIcon,
  // Layout as LayoutIcon,
  // Edit as EditIcon,
  // DollarSign as DollarSignIcon,
  // Mail as MailIcon,
  // MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  // Share2 as ShareIcon,
  Users as UsersIcon,
  // Layers as CategoryIcon,
  // Flag as CountryIcon,
  // Globe as GlobeIcon,
  // Package as PackageIcon,
  // FileText as FileIcon,
  Percent as PercentIcon,
  DollarSign as DollarSignIcon,
  BookOpen as BookOpenIcon,
  Book as BookIcon,
  HelpCircle as HelpCircleIcon,
  Phone as PhoneIcon,
  ThumbsUp as ThumbsUpIcon,
  Share as ShareIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import NavItem from './NavItem';

function renderNavItems({
  items, pathname, userRole, depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({
          acc, item, pathname, depth, userRole
        }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc, pathname, item, depth, userRole
}) {
  const key = item.title + depth;
  let checkRole = false;
  const { role: itemRole } = item;
  if (!itemRole) checkRole = true;
  if (itemRole) {
    if (userRole === 'admin') checkRole = true;
    else if (Array.isArray(itemRole) && itemRole.includes(userRole)) checkRole = true;
  }

  if (checkRole) {
    if (item.items) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false
      });

      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
          })}
        </NavItem>
      );
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
        />
      );
    }
  }
  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { getUserRole } = useAuth();
  const userRole = getUserRole();

  const sections = [
    {
      subheader: t('Reports'),
      items: [
        {
          title: t('Dashboard'),
          icon: PieChartIcon,
          href: '/app/reports/dashboard'
        },
        // {
        //   title: 'Dashboard Alternative',
        //   icon: BarChartIcon,
        //   href: '/app/reports/dashboard-alternative'
        // }
      ]
    },
    {
      subheader: t('Management'),
      items: [
        {
          title: t('Users'),
          icon: UserIcon,
          href: '/app/management/users',
          role: ['admin'],
          items: [
            {
              title: t('List'),
              href: '/app/management/users'
            },
            {
              title: t('Add new'),
              href: '/app/management/users/create'
            }
          ]
        },
        {
          title: t('Customers'),
          icon: UsersIcon,
          href: '/app/management/customers',
          role: ['admin', 'staff'],
          items: [
            {
              title: t('List'),
              href: '/app/management/customers'
            },
            {
              title: t('Add new'),
              href: '/app/management/customers/create'
            }
          ]
        },
        {
          title: t('Loans'),
          icon: BriefcaseIcon,
          href: '/app/management/loans',
          role: ['admin', 'staff'],
          items: [
            {
              title: t('List'),
              href: '/app/management/loans'
            },
            {
              title: t('Add new'),
              href: '/app/management/loans/create'
            }
          ]
        },
        {
          title: t('Loan entrustments'),
          icon: BriefcaseIcon,
          href: '/app/management/loan-entrustments',
          role: ['admin', 'staff'],
          items: [
            {
              title: t('List'),
              href: '/app/management/loan-entrustments'
            },
            {
              title: t('Add new'),
              href: '/app/management/loan-entrustments/create'
            }
          ]
        },
        {
          title: t('Saving deposits'),
          icon: BriefcaseIcon,
          href: '/app/management/savings',
          role: ['admin', 'staff'],
          items: [
            {
              title: t('List'),
              href: '/app/management/savings'
            },
            {
              title: t('Add new'),
              href: '/app/management/savings/create'
            }
          ]
        },
        {
          title: t('Saving interest rates'),
          icon: PercentIcon,
          href: '/app/management/saving-interest-rates',
          role: ['admin', 'staff']
        },
        {
          title: t('Loan interest rates'),
          icon: PercentIcon,
          href: '/app/management/loan-interest-rates',
          role: ['admin', 'staff']
        },
        // {
        //   title: 'Products',
        //   icon: ShoppingCartIcon,
        //   href: '/app/management/products',
        //   items: [
        //     {
        //       title: 'List Products',
        //       href: '/app/management/products'
        //     },
        //     {
        //       title: 'Create Product',
        //       href: '/app/management/products/create'
        //     }
        //   ]
        // },
        // {
        //   title: 'Orders',
        //   icon: FolderIcon,
        //   href: '/app/management/orders',
        //   items: [
        //     {
        //       title: 'List Orders',
        //       href: '/app/management/orders'
        //     },
        //     {
        //       title: 'View Order',
        //       href: '/app/management/orders/1'
        //     }
        //   ]
        // }
      ]
    },
    {
      subheader: t('Settings'),
      items: [
        {
          title: t('Terms of use'),
          icon: BookOpenIcon,
          href: '/app/management/settings/terms-of-use',
          role: ['admin', 'staff']
        },
        {
          title: t('Terms of loan'),
          icon: DollarSignIcon,
          href: '/app/management/settings/terms-of-loan',
          role: ['admin', 'staff']
        },
        {
          title: t('Faq'),
          icon: HelpCircleIcon,
          href: '/app/management/settings/faq',
          role: ['admin', 'staff']
        },
        {
          title: t('Contact'),
          icon: PhoneIcon,
          href: '/app/management/settings/contact',
          role: ['admin', 'staff']
        },
        {
          title: t('loan_type_loan_for_life'),
          icon: BookIcon,
          href: '/app/management/settings/loan-for-life',
          role: ['admin', 'staff']
        },
        {
          title: t('loan_type_loan_for_business'),
          icon: BookIcon,
          href: '/app/management/settings/loan-for-business',
          role: ['admin', 'staff']
        },
        {
          title: t('Loan entrustment'),
          icon: ThumbsUpIcon,
          href: '/app/management/settings/loan-entrustment',
          role: ['admin', 'staff']
        },
        {
          title: t('Transfer money'),
          icon: ShareIcon,
          href: '/app/management/settings/transfer-money',
          role: ['admin', 'staff']
        }
      ]
    },
    // {
    //   subheader: t('applications'),
    //   items: [
    //     // {
    //     //   title: 'Projects Platform',
    //     //   href: '/app/projects',
    //     //   icon: BriefcaseIcon,
    //     //   items: [
    //     //     {
    //     //       title: 'Overview',
    //     //       href: '/app/projects/overview'
    //     //     },
    //     //     {
    //     //       title: 'Browse Projects',
    //     //       href: '/app/projects/browse'
    //     //     },
    //     //     {
    //     //       title: 'Create Project',
    //     //       href: '/app/projects/create'
    //     //     },
    //     //     {
    //     //       title: 'View Project',
    //     //       href: '/app/projects/1'
    //     //     }
    //     //   ]
    //     // },
    //     // {
    //     //   title: 'Social Platform',
    //     //   href: '/app/social',
    //     //   icon: ShareIcon,
    //     //   items: [
    //     //     {
    //     //       title: 'Profile',
    //     //       href: '/app/social/profile'
    //     //     },
    //     //     {
    //     //       title: 'Feed',
    //     //       href: '/app/social/feed'
    //     //     }
    //     //   ]
    //     // },
    //     // {
    //     //   title: 'Kanban',
    //     //   href: '/app/kanban',
    //     //   icon: TrelloIcon
    //     // },
    //     // {
    //     //   title: 'Mail',
    //     //   href: '/app/mail',
    //     //   icon: MailIcon
    //     // },
    //     // {
    //     //   title: 'Chat',
    //     //   href: '/app/chat',
    //     //   icon: MessageCircleIcon,
    //     //   info: () => <Chip color="secondary" size="small" label="Updated" />
    //     // },
    //     // {
    //     //   title: t('Events'),
    //     //   href: '/app/calendar',
    //     //   icon: CalendarIcon
    //     //   // info: () => <Chip color="secondary" size="small" label="Updated" />
    //     // }
    //   ]
    // }
    // {
    //   subheader: 'Auth',
    //   items: [
    //     {
    //       title: 'Login',
    //       href: '/login-unprotected',
    //       icon: LockIcon
    //     },
    //     {
    //       title: 'Register',
    //       href: '/register-unprotected',
    //       icon: UserPlusIcon
    //     }
    //   ]
    // },
    // {
    //   subheader: 'Pages',
    //   items: [
    //     {
    //       title: 'Account',
    //       href: '/app/account',
    //       icon: UserIcon
    //     },
    //     {
    //       title: 'Error',
    //       href: '/404',
    //       icon: AlertCircleIcon
    //     },
    //     {
    //       title: 'Pricing',
    //       href: '/pricing',
    //       icon: DollarSignIcon
    //     }
    //   ]
    // },
    // {
    //   subheader: 'Extra',
    //   items: [
    //     {
    //       title: 'Charts',
    //       href: '/app/extra/charts',
    //       icon: BarChartIcon,
    //       items: [
    //         {
    //           title: 'Apex Charts',
    //           href: '/app/extra/charts/apex'
    //         }
    //       ]
    //     },
    //     {
    //       title: 'Forms',
    //       href: '/app/extra/forms',
    //       icon: EditIcon,
    //       items: [
    //         {
    //           title: 'Formik',
    //           href: '/app/extra/forms/formik'
    //         },
    //         {
    //           title: 'Redux Forms',
    //           href: '/app/extra/forms/redux'
    //         }
    //       ]
    //     },
    //     {
    //       title: 'Editors',
    //       href: '/app/extra/editors',
    //       icon: LayoutIcon,
    //       items: [
    //         {
    //           title: 'DraftJS Editor',
    //           href: '/app/extra/editors/draft-js'
    //         },
    //         {
    //           title: 'Quill Editor',
    //           href: '/app/extra/editors/quill'
    //         }
    //       ]
    //     }
    //   ]
    // }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo style={{ width: 30, height: 30 }} />
            </RouterLink>
          </Box>
        </Hidden>
        {/* <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.username}
            </Link>
          </Box>
        </Box>
        <Divider /> */}
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                userRole
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
