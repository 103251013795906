import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  inputSearch: {},
};

const actions = {
  UPDATE_SEARCH_INPUT: 'UPDATE_SEARCH_INPUT',
  CLEAR_STATE: 'CLEAR_STATE'
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.UPDATE_SEARCH_INPUT: {
      return { ...state, inputSearch: action.text.payload };
    }
    case actions.CLEAR_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

const InputSearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = {
    inputSearch: state.inputSearch,
    updateInputSearch: (text) => {
      dispatch({ type: actions.UPDATE_SEARCH_INPUT, text });
    },
    clearState: () => {
      dispatch({ type: actions.CLEAR_STATE });
    }
  };
  return (
    <InputSearchContext.Provider value={value}>
      {children}
    </InputSearchContext.Provider>
  );
};

InputSearchProvider.propTypes = {
  children: PropTypes.object,
};

export const InputSearchContext = createContext();

export default InputSearchProvider;
