import axios from 'axios';
import ApiError from './ApiError';
import { mapKeysCamelCase, mapKeysSnakeCase } from './ConvertObject';
import axiosInstance from './AxiosInstance';

export default class Api {
  static apiUrls = {
    localhost: process.env.REACT_APP_LOCAL_API_URL,
    'admin.dongsaigonfund.com': process.env.REACT_APP_LIVE_API_URL,
  }

  static apiToken = ''

  static getApiUrl = (path) => {
    const url = this.apiUrls[window.location.hostname] || process.env.REACT_APP_LOCAL_API_URL;
    return url + path;
  }

  static getImageUrl = (page) => {
    const url = this.apiUrls.imageUrl;
    return `${url}${page}`;
  }

  static get(url, params, options = {}) {
    return this.request(
      {
        method: 'GET',
        url,
        params,
        ...options
      }
    );
  }

  static post(url, data, options = {}) {
    return this.request(
      {
        method: 'POST',
        url,
        data,
        ...options
      }
    );
  }

  static put(url, data, options = {}) {
    return this.request(
      {
        method: 'PUT',
        url,
        data,
        ...options
      }
    );
  }

  static delete(url, data, options = {}) {
    return this.request(
      {
        method: 'DELETE',
        url,
        data,
        ...options
      }
    );
  }

  static cancelRequest = null

  static request = async (options) => {
    options.disableMap = options.disableMap !== undefined ? options.disableMap : false;
    // op
    if (options.data && !options.disableMap) {
      options.data = mapKeysSnakeCase(options.data);
    }
    if (options?.useFormDataRequest) {
      const formData = new FormData();
      Object.keys(options.data).forEach((key) => {
        if (Array.isArray(options.data[key])) {
          options.data[key].forEach((p) => {
            formData.append(key, p);
          });
        } else {
          formData.append(key, options.data[key]);
        }
      });
      options.data = formData;
    }

    if (options.params && !options.disableMap) {
      options.params = mapKeysSnakeCase(options.params);
    }
    this.cancelRequest = axios.CancelToken.source();
    options.cancelToken = this.cancelRequest.token;

    if (options.hostType === 'auth') {
      options.headers = {
        Authorization: `Bearer ${this.apiToken}`
      };
    }
    // console.log('api token', this.apiToken, options.hostType)
    // console.log('request api =>', options.url, options.data, options.params);

    const response = await axiosInstance(options).catch((err) => {
      if (axios.isCancel(err)) {
        // throw new Error('request_canceled')
        throw new ApiError('request_canceled', options);
      }
      // detect network error
      if (!err.response && err.message === 'Network Error') {
        throw new ApiError('network_error', options);
      }

      return err.response;
    });
    // console.log(response)
    if (!response) {
      throw new ApiError('server_error', {
        request: options.data,
        response
      });
    }
    response.data = mapKeysCamelCase(response.data);

    if (response.status >= 500) {
      console.log('Server error', response);
      throw new ApiError('server_error', {
        request: options,
        response: response.data
      });
    } else if (response.status === 401) {
      console.log('Authenticate failed', options.url);
      throw new ApiError('authenticate_failed', {
        request: options,
        response: response.data
      });
    } else if (response.status === 422) {
      console.log('Invalid data');
      throw new ApiError('invalid_params', {
        request: options,
        response: response.data
      });
    } else if (response.status === 404 || response.status === 405) {
      console.log('Not found', options.url);
      throw new ApiError('not_found', {
        request: options,
        response: response.data
      });
    } else if (response.status >= 400 && response.status <= 499) {
      console.log('Bad request');
      throw new ApiError('bad_request', {
        request: options,
        response: response.data
      });
    }
    return {
      status: response.status,
      headers: response.headers,
      data: response.data,
      request: options.data || options.params
    };
  }

  static parseToQueryString = (params) => {
    return Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join('&');
  }

  static isCancelRequest = (err) => {
    return err && err.name === 'request_canceled';
  }

  static isErrorAuthenticateFailed = (err) => {
    return err && err.name === 'authenticate_failed';
  }

  static isErrorInvalidParams = (err) => {
    return err && err.name === 'invalid_params';
  }

  static isNetworkError = (err) => {
    return err && err.name === 'network_error';
  }

  static isNotFoundError = (err) => {
    return err && err.name === 'not_found';
  }

  static isApiError = (err) => {
    return err instanceof ApiError;
  }
}
