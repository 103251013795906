import React from 'react';
import { Alert } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { TAXES } from '../constants';
import Api from './Api';
import { green, orange } from '@material-ui/core/colors';

export default class Commons {

  static parseApiError = (resp) => {
    const errs = [];
    if (resp.errors) {
      Object.keys(resp.errors).forEach((field) => {
        resp.errors[field].forEach((mes) => {
          errs.push({
            field,
            visible: true,
            message: mes,
          });
        });
      });
    } else if (resp.message) {
      errs.push({
        field: '',
        visible: true,
        message: resp.message,
        data: resp.data
      });
    }
    return errs;
  }

  static setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      Api.apiToken = accessToken;
    } else {
      localStorage.removeItem('accessToken');
      Api.apiToken = null;
    }
  };

  static currencies = {
    jpy: '¥',
    euro: '€',
    us: '$'
  };
  
  static formatDateStr = 'DD/MM/YYYY';

  static formatDateTimeStr = 'HH:mm DD/MM/YYYY';

  static formatDateTimeSecondStr = 'HH:mm:ss DD/MM/YYYY';
  
  static saveFormatDateTimeStr = 'YYYY-MM-DD';

  static saveFormatDateTimeSecondStr = 'YYYY-MM-DD HH:mm:ss';

  static formatNumber = (value, format) => {
    return numeral(value).format(format || '0,0.00');
  };

  static formatMoney = (value, format) => {
    if (value < 0) {
      return `${this.currencies.jpy} ${this.formatMoneyWithoutCurrency(value, format)}`;
    }
    return this.currencies.jpy + this.formatMoneyWithoutCurrency(value, format);
  };

  static formatMoneyWithoutCurrency = (value, format) => {
    return numeral(value).format(format || '0,0[.]00');
  };

  static formatKNumber = (num) => {
    return Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k` : Math.sign(num) * Math.abs(num);
  }

  static customersAttributes = [
    {
      id: 1,
      label: 'System Developtment'
    },
    {
      id: 2,
      label: '2nd hand fashion shop'
    },
    {
      id: 3,
      label: 'General shop'
    },
    {
      id: 9,
      label: 'Others'
    },
  ]

  // static customersAttributes = [
  //   {
  //     id: 1,
  //     label: 'System Developtment'
  //   },
  //   {
  //     id: 2,
  //     label: '2nd hand fashion shop'
  //   },
  //   {
  //     id: 3,
  //     label: 'General shop'
  //   },
  //   {
  //     id: 9,
  //     label: 'Others'
  //   },
  // ]

  static customersPicAttributes = [
    {
      id: 1,
      label: 'CEO'
    },
    {
      id: 2,
      label: 'Buyer'
    },
    {
      id: 3,
      label: 'Staff'
    },
    {
      id: 9,
      label: 'Others'
    },
  ]

  static getAttributeLabel = (id, attributes) => {
    // eslint-disable-next-line eqeqeq
    const exists = attributes.find((x) => x.id == id);
    return exists ? exists.label : null;
  }

  static contactMethods = [
    {
      id: 'line',
      label: 'Line'
    },
    {
      id: 'instagram',
      label: 'Instagram'
    },
    {
      id: 'chatwork',
      label: 'Chatwork'
    },
    {
      id: 'email',
      label: 'Email'
    },
    {
      id: 'phone_number',
      label: 'Phone Number'
    },
  ]

  static paymentMethods = [
    {
      id: 1,
      label: 'Open Account'
    },
    {
      id: 2,
      label: 'Pay For Each Time'
    },
    // {
    //   id: 3,
    //   label: 'Pay After Delivered'
    // },
    {
      id: 6,
      label: 'Cash'
    },
    // {
    //   id: 5,
    //   label: 'Ship After Money Arrived'
    // },
    {
      id: 7,
      label: 'Advance Transfer'
    },
    {
      id: 8,
      label: 'Post transfer'
    },
    {
      id: 4,
      label: 'Cash On Delivery'
    },  
    {
      id: 9,
      label: 'Credit Card On Delivery'
    },
    {
      id: 10,
      label: 'Other'
    },
  ]

  static billingContactMethods = [
    {
      id: 'line',
      label: 'Line'
    },
    {
      id: 'chatwork',
      label: 'Chatwork'
    },
    {
      id: 'email',
      label: 'Email'
    },
    {
      id: 'others',
      label: 'Others'
    },
  ]

  static paymentCycles = [
    {
      id: 10,
      label: '10'
    },
    {
      id: 20,
      label: '20'
    },
    {
      id: 30,
      label: '30'
    },
    {
      id: 31,
      label: '31'
    },
    {
      id: 7,
      label: '7'
    },
  ]

  static orderLogEditMap = [
    { key: 'order_date', label: 'Order Date' },
    { key: 'order_type', label: 'Order Type' },
    { key: 'tax', label: 'Tax' },
    { key: 'template_message', label: 'Template Message' },
    { key: 'note', label: 'Note' },
    {
      key: 'user_id',
      label: 'Person In Charge(In house)',
      nestedData: 'user',
      nestedKey: 'name'
    },
    {
      key: 'customer_id',
      label: 'Client Name',
      nestedData: 'customer',
      nestedKey: 'client_name'
    },
    {
      key: 'customer_pic_id',
      label: 'Customer PIC',
      nestedData: 'customer_pic',
      nestedKey: 'contact_name'
    },
    {
      key: 'customer_shop_id',
      label: 'Delivery Address',
      nestedData: 'customer_shop',
      nestedKey: 'address'
    },
    { key: 'visit_date', label: 'Visit Date' },
    { key: 'payment_method', label: 'Payment Method' },
    { key: 'discount', label: 'Discount Rate' },
    { key: 'delivery_number', label: 'Delivery Number' },
    { key: 'delivery_date', label: 'Delivery Date' },
    { key: 'delivery_status', label: 'Delivery Status' },
    { key: 'shipping_person', label: 'Shipping Person' }
  ];

  static orderLogItemEditMap = [
    {key: 'activity', label: 'Actions'},
    {key: 'jan_code', label: 'JAN'},
    {key: 'unit_price', label: 'Unit Price'},
    {key: 'product_name', label: 'Product Name'},
    {key: 'sales_quantity', label: 'Quantity'},
  ]

  static getPaymentCycles(pm) {
    const paymentMethod = parseInt(pm, 10);
    if (paymentMethod === 1) {
      return this.paymentCycles.filter((x) => [10, 20, 31].includes(x.id)) || [];
    }
    if (paymentMethod === 4) {
      return this.paymentCycles.filter((x) => [30].includes(x.id)) || [];
    }

    return this.paymentCycles.filter((x) => [7].includes(x.id)) || [];
  }

  static getRoleOfUser = (user) => {
    const isAdmin = user && user.admin_flag;
    const isManager = user && !isAdmin && user.manager_flag;
    const isAccountant = !isAdmin && !isManager && user.accountant_flag;
    const isOperator = !isAdmin && !isManager && !isAccountant;
    // eslint-disable-next-line no-nested-ternary
    const role = isAdmin ? 'admin' : (isManager ? 'manager' : (isAccountant) ? 'accountant' : 'operator');
    return {
      isAdmin,
      isManager,
      isOperator,
      isAccountant,
      role
    };
  }

  static getTax = () => {
    return 10;
  }

  static getTaxOfPrice = (price, taxPercent) => {
    return (price * Number(taxPercent)) / 100;
  }

  static getPriceAfterTax = (price, taxPercent) => {
    // const tax = (price * Number(taxPercent)) / 100;
    return price + Commons.getTaxOfPrice(price, Number(taxPercent));
  }

  static getDiscountOfPrice = (price, discount) => {
    return (price * Number(discount)) / 100;
  }

  static getPriceAfterDIscount = (price, discount) => {
    return price - ((price * Number(discount)) / 100);
  }

  static generateOrderId(order) {
    return `${moment(order.order_date).format('YYYYMMDD')}-${order.id}`;
  }

  static generateInvoiceId(invoice) {
    return `${moment(invoice.created_at).format('YYYYMMDD')}-${invoice.id}`;
  }

  static generateJanCode(barcode) {
    barcode += '';
    let result = 0;
    let i = 1;
    for (let counter = barcode.length - 1; counter >= 0; counter--) {
      result += Number(barcode.charAt(counter)) * (1 + (2 * (i % 2)));
      i++;
    }
    const newNumber = (10 - (result % 10)) % 10;
    return {
      digit: newNumber,
      janCode: barcode + newNumber
    };
  }

  static isNumber = (n) => { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); };

  static prettyFloat = (value, precision, localize) => {
    if (value === 0) {
      return value;
    }
    value = value || '';
    precision = precision || 0;
    localize = localize || false;
    // eslint-disable-next-line no-restricted-globals
    const rounded = (!isNaN(precision) && parseInt(precision, 10) > 0)
      ? parseFloat(value).toFixed(parseInt(precision, 10))
      : value;

    const trimmed = parseFloat(rounded).toString();

    // eslint-disable-next-line no-restricted-globals
    if (localize && !isNaN(trimmed)) {
      return parseFloat(trimmed).toLocaleString();
    }

    return trimmed;
  };

  static focusTextField = (ev) => {
    ev.preventDefault();
    const { target } = ev;
    const extensionStarts = target.value.lastIndexOf('.');
    target.focus();
    target.setSelectionRange(0, extensionStarts);
  }


  static isInvoiceCustomer = (customer) => {
    return customer.payment_method === 1 || customer.payment_method === 3;
  }

  static ObjIsDeleted(obj) {
    return !!obj?.deleted_at;
  }

  static getDeletedText(obj, value) {
    return this.ObjIsDeleted(obj) ? `${value}${i18n.t('Deleted')}` : value;
  }

  static getCustomerOptionLabel = (option) => {
    return !option.client_name ? '' : `${option.client_name}${option?.furi_client_name ? `(${option.furi_client_name})` : ''}${this.getDeletedText(option, '')}`;
  };

  static getCustomerPicOptionLabel = (option) => {
    return !option.contact_name ? '' : `${option.contact_name}${option?.furi_contact_name ? `(${option.furi_contact_name})` : ''}${this.getDeletedText(option, '')}`;
  };

  static pdfGetWidthOfText = (text) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    return context.measureText(text).width;
  };

  static pdfGetFontOfText = (receiverTitle, limitFont, limitTextWidth) => {
    const limitFonts = limitFont || 12;
    const limitTextWidths = limitTextWidth || 180;
    return this.pdfGetWidthOfText(receiverTitle) > limitTextWidths
      ? limitFonts / (this.pdfGetWidthOfText(receiverTitle) / limitTextWidths)
      : limitFonts;
  };

  static convertFullToHalf = (str) => {
    return str.replace(/[！-～]/g, (r) => String.fromCharCode(r.charCodeAt(0) - 0xFEE0));
  };

  static calculateTotalWithEachTax = (data) => {
    let totalPriceAndTax = {
    };
    data.orders.forEach((order) => {
      const taxes = TAXES.find(tax => tax.value === order.tax);
      totalPriceAndTax[`tax_${taxes.value}`] = {
        ...totalPriceAndTax[`tax_${taxes.value}`],
        total: (totalPriceAndTax[`tax_${taxes.value}`]?.total || 0) + order.total_without_tax
      };
      totalPriceAndTax[`tax_${taxes.value}`] = {
        ...totalPriceAndTax[`tax_${taxes.value}`],
        tax: Math.floor(((totalPriceAndTax[`tax_${taxes.value}`]?.total || 0)) * taxes.value / 100),
      };
    })
    let total = 0;
    Object.keys(totalPriceAndTax).map(key => {
        total += totalPriceAndTax[key].tax
    })
    totalPriceAndTax.total_tax = total;

    return totalPriceAndTax;
  }

  static getOverdueDate = (date) => {
    const today = moment().format(this.formatDateStr);
    const compare = moment(date, this.formatDateStr);
    const valid = moment(date).isValid();

    return valid ? compare.diff(today, 'days') : '/';
  };

  static getAuthStatusColor = (status) => {
    return status === 'auth' ? green[500] : orange[400]
  }
}

export const ErrorMessages = ({ errorMessages, onClose }) => {
  const errors = errorMessages || [];
  const { t } = useTranslation();

  return (
    <>
      {errors.map((err) => (
        err.visible && (
        <Box key={err} mb={3}>
          <Alert onClose={() => { err.visible = false; onClose(errors); }} severity="error">
            {t(err.message, { data: err.data })}
          </Alert>
        </Box>
        )
      ))}
    </>
  );
};

ErrorMessages.propTypes = {
  errorMessages: PropTypes.array,
  onClose: PropTypes.func
};

ErrorMessages.defaultProps = {
  errorMessages: []
};
