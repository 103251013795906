import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const AdminStaffGuard = ({ role, children }) => {
  const { isAuthenticated, getUserRole } = useAuth();
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  if (!role.includes(getUserRole())) {
    return <Redirect to="/app/reports/dashboard" />;
  }

  return <>{children}</>;
};

AdminStaffGuard.propTypes = {
  children: PropTypes.node,
  role: PropTypes.array
};

export default AdminStaffGuard;
