export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const PLACES = [
  '大阪（富田林）',
  '東京（下北沢）',
  '大介',
  '大阪倉庫シフト',
  '東京オフィスシフト',
  '喜志MUMUシフト',
  '下北MUMUシフト',
  '大阪ピック',
  '東京ピック',
  '電話ピック',
  '大介さん',
  '来客＆イベント',
  'タイ',
];

export const CSV_FORMATS = [
  'text/csv',
  'text/x-csv',
  'application/csv',
  'application/x-csv',
  'application/vnd.ms-excel',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
  'text/tab-separated-values',
  'text/plain',
];

export const DEFAULT_EXPORT_META = {
  delivery_card: {
    created_date: ''
  },
  invoice: {
    created_date: ''
  },
  receipt: {
    created_date: ''
  }
};

export const TAXES = [
  { label: '10%', value: 10 },
  { label: '8%', value: 8 },
  { label: '5%', value: 5 },
  { label: 'No tax', value: 0 },
];

export const ROOT_ADMIN_EMAIL = 'admin@dongsaigongfund.com';

export const LOAN_TYPES = ['loan_for_life', 'loan_for_business'];
export const LOAN_PAY_INTEREST_TYPES = ['weekly', 'monthly', 'quarterly', 'end'];

export const LOAN_TYPE_ASSETS = ['house', 'land', 'car', 'market_stall', 'stock', 'bank_savings', 'other'];
export const LOAN_STATUS = ['pending', 'approve', 'reject', 'spam'];
export const SAVING_RECEIVING_TYPE = ['monthly', 'quarterly', 'end'];

export const SETTING_NAMES = {
  terms_of_use: 'terms_of_use',
  terms_of_loan: 'terms_of_loan',
  faq: 'faq',
  contact: 'contact',
  loan_for_life: 'loan_for_life',
  loan_for_business: 'loan_for_business',
  loan_entrustment: 'loan_entrustment',
  transfer_money: 'transfer_money',
  interest_rate_file_url: 'interest_rate_file_url',
};
